<template>
  <div class="page-container">
    <div class="container">
      <b-row align-v="center" class="row pt-3 pb-3">
        <div class="col-3">
          <b-form-group
            :label="$t('from')"
            label-for="from"
            content-cols="2"
            label-align="top"
            label-size="sm"
            align="left"
          >
            <datetime
              id="from"
              v-model="fromDate"
              input-class="form-control form-control-sm"
            ></datetime>
          </b-form-group>
        </div>
        <div class="col-3">
          <b-form-group
            :label="$t('untilAndIncluding')"
            label-for="to"
            content-cols="2"
            label-align="top"
            label-size="sm"
            align="left"
          >
            <datetime
              id="to"
              v-model="toDate"
              title="To"
              input-class="form-control form-control-sm"
            ></datetime>
          </b-form-group>
        </div>
        <div class="col-3">
          <b-form-group
            :label="$t('search')"
            label-for="search"
            content-cols="2"
            label-align="top"
            label-size="sm"
            align="left"
          >
            <b-form-input
              id="search"
              v-model="search"
              :placeholder="$t('typeToSearch')"
              debounce="500"
              class="form-control-sm"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-3">
          <b-form-group class="mt-3" align-v="center">
            <b-form-radio-group v-model="allBuildings" size="sm">
              <b-form-radio value="false">{{
                $t('thisBuilding')
              }}</b-form-radio>
              <b-form-radio value="true">{{
                $t('allBuildings')
              }}</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
      </b-row>
      <div>
        <b-tabs card content-class="mt-3">
          <b-tab
            :title="$t('monthlySummaries')"
            class="p-0"
            title-item-class="title-tab-with-card"
            active
          >
            <b-table
              striped
              hover
              :items="monthlySummaries"
              :fields="monthFields"
              show-empty
              ><template #cell(noShows)="data">
                {{
                  parseInt(data.item.reservations) -
                    parseInt(data.item.checkins)
                }}
              </template></b-table
            >
            <b-button
              v-if="monthlySummaries.length"
              @click="csvExport(monthlySummaries, 'monthly-summary-by-user')"
              >{{ $t('export') }}</b-button
            >
          </b-tab>
          <b-tab
            :title="$t('weeklySummaries')"
            class="p-0"
            title-item-class="title-tab-with-card"
            variant="secondary"
          >
            <b-table
              striped
              hover
              :items="weeklySummaries"
              :fields="weekFields"
              show-empty
              ><template #cell(noShows)="data">
                {{
                  parseInt(data.item.reservations) -
                    parseInt(data.item.checkins)
                }}
              </template></b-table
            >
            <b-button
              v-if="weeklySummaries.length"
              @click="csvExport(weeklySummaries, 'weekly-summary-by-user')"
              >{{ $t('export') }}</b-button
            >
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import OkkuApi from '@/services/OkkuApi'
import { Datetime } from 'vue-datetime'
import moment from 'moment-timezone'
import { exportAsCSVMixin } from '@/mixins/mixins'

export default {
  name: 'MonthlyReportByUser',
  components: {
    datetime: Datetime
  },
  mixins: [exportAsCSVMixin],
  data() {
    return {
      weeklySummaries: [],
      monthlySummaries: [],
      fromDate: moment()
        .startOf('month')
        .add(1, 'days')
        .toISOString(),
      toDate: moment()
        .endOf('month')
        .toISOString(),
      search: undefined,
      allBuildings: false,
      weekFields: [
        {
          key: 'week',
          label: this.$t('week'),
          sortable: true
        },
        {
          key: 'firstName',
          sortable: false,
          label: this.$t('firstName')
        },
        {
          key: 'lastName',
          sortable: true,
          label: this.$t('lastName')
        },
        {
          key: 'employeeNumber',
          label: this.$t('employeeNumber'),
          sortable: true
        },
        {
          key: 'reservations',
          label: this.$t('reservations'),
          sortable: true
        },
        {
          key: 'checkins',
          label: this.$t('checkIns'),
          sortable: true
        },
        {
          key: 'buildingCheckInsCount',
          label: this.$t('buildingCheckIns'),
          sortable: true
        },
        {
          key: 'noShows',
          label: this.$t('no-shows'),
          sortable: true
        }
      ],
      monthFields: [
        {
          key: 'year',
          label: this.$t('year'),
          sortable: true
        },
        {
          key: 'month',
          label: this.$t('month'),
          sortable: true
        },
        {
          key: 'firstName',
          sortable: false,
          label: this.$t('firstName')
        },
        {
          key: 'lastName',
          sortable: true,
          label: this.$t('lastName')
        },
        {
          key: 'employeeNumber',
          label: this.$t('employeeNumber'),
          sortable: true
        },
        {
          key: 'reservations',
          label: this.$t('reservations'),
          sortable: true
        },
        {
          key: 'checkins',
          label: this.$t('checkIns'),
          sortable: true
        },
        {
          key: 'buildingCheckInsCount',
          label: this.$t('buildingCheckIns'),
          sortable: true
        },
        {
          key: 'noShows',
          label: this.$t('no-shows'),
          sortable: true
        }
      ]
    }
  },
  watch: {
    fromDate() {
      this.getSummaries()
    },
    toDate() {
      this.getSummaries()
    },
    search() {
      this.getSummaries()
    },
    allBuildings() {
      this.getSummaries()
    }
  },
  mounted() {
    this.getSummaries()
  },
  methods: {
    home(event) {
      window.location.href = '/workplace'
    },
    async getSummaries() {
      const monthlySummaries = await OkkuApi.getMonthlyReportByUser(
        this.fromDate,
        this.toDate,
        this.search,
        this.allBuildings === 'true'
      )
      this.monthlySummaries = monthlySummaries
      const weeklySummaries = await OkkuApi.getWeeklyReportByUser(
        this.fromDate,
        this.toDate,
        this.search,
        this.allBuildings === 'true'
      )
      this.weeklySummaries = weeklySummaries
    }
  }
}
</script>

<style lang="scss">
// For overwriting default link color in tab
.title-tab-with-card .nav-link {
  color: var(--white);
}

.title-tab-with-card .nav-link:hover {
  background-color: #00a8a9;
  border-color: #00a8a9;
  color: var(--white);
}
</style>
